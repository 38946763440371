html,div#root {
  background-color: black;
}

.site-content {
  background-color: black !important;
}

.error-text {
  color: red  !important;
  font-size: 12px !important;
}

.text{
  font-family:'Courier New', Courier, monospace;
  font-size: 18px;
  color: #ffffff;
}

.title{
  font-family:'Courier New', Courier, monospace;
  font-size: 35px;
  font-weight: bold;
  color: #ffffff;
}

.sub-title{
  font-family:'Courier New', Courier, monospace;
  font-size: 22px;
  font-weight: bold;
  color: #ffffff;
}

input.PhoneInputInput{
  height: 40px;
  border-radius: 7px;
  font-family:'Courier New', Courier, monospace;
}

.home-content {
  font-family: Courier, monospace;
  color: #ffffff;
}

.company-name{
  font-size: 5vw;
  font-family: Courier, monospace;
  color: #ffffff;
}

.data-container {
  font-size: calc(0.8vw + 1.2vh);
  text-align: center;
  white-space: pre-wrap;
  overflow: hidden;
  animation: slideIn 10s linear infinite;
  font-family: Courier, monospace;
  color: #ffffff;
}

.footer {
  font-family: Courier, monospace;
  color: #ffffff;
}

.poppins {
  font-family: "Poppins";
}

.sideBar{
  background-color: #ffffff !important;
  /* color: rgb(241, 243, 245) !important; */
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif !important;
  font-weight: 700;
  font-size: large;
  cursor: pointer !important;
}

.sideBar .side-menu :hover {
  background-color: #abb2bd !important;
  border-radius: 1.2rem !important;
}

.content.active {
  background-color: #abb2bd  !important;
  border-radius: 1.2rem !important;
}

.fixed-sidebar {
  position: fixed;
  top: 20;
  left: 0;
  height: 100vh;
  overflow-y: hidden;
}

.content-area {
  margin-left: 16.6667%; /* Adjust according to sidebar width, 2 columns = 16.6667% */
  padding-top: 20px;
}